import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage } = config;

  const goToFb = 'Go to Facebook page';
  const goToInsta = 'Go to Instagram page';

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <NamedLink name="LandingPage" className={css.logoLink}>
            <span className={css.logo}>
              <Logo format="desktop" />
            </span>
          </NamedLink>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  Delicious Experiences helps companies <strong>bring remote teams together</strong>{' '}
                  to celebrate and deepen their bond,{' '}
                  <strong>strengthen connections with clients,</strong> and{' '}
                  <strong>improve employee happiness.</strong> Our unique culinary experiences focus
                  on bringing people together through engaging, fun, virtual, hands-on events that
                  help catapult your teams and clients to a different time and place.
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    &copy; Delicious Experiences 2025
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li>
                  <NamedLink name="LandingPage">Home</NamedLink>
                </li>
                <li>
                  <a href="/blog/">Blog</a>
                </li>
                <li>
                  <NamedLink name="AboutPage">About us</NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li>
                  <a href="/contact">Contact</a>
                </li>
                <li>
                  <a href="/host-an-experience">Host an experience</a>
                </li>
              </ul>
            </div>
            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <a href="/terms-of-service" className={css.legalLink}>
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" className={css.legalLink}>
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              &copy; Delicious Experiences 2025
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <li>
                <a href="/terms-of-service" className={css.legalLink}>
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/privacy-policy" className={css.legalLink}>
                  Privacy Policy
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
};

export default Footer;
