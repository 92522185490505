import React, { useEffect } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import css from './SectionHero.css';

const SectionHero = props => {
  const {
    rootClassName,
    className,
    onManageTopbarBelow,
    topbarBelow,
    featuredListings,
    // onManageRequestCatalog,
  } = props;

  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (topbarBelow === inView) {
      onManageTopbarBelow(!inView);
    }
  }, [inView, onManageTopbarBelow, topbarBelow]);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes} ref={ref}>
      <div className={css.heroContent}>
        <div className={css.heroTitles}>
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <div className={css.buttonsContainer}>
            <AnchorLink href="#how-it-works" className={css.lstHeroBtn}>
              Tell me more
            </AnchorLink>
            <a href="/static/events-catalog.pdf" className={css.lstHeroBtnWinter} target="_blank">
              Discover Winter Options
            </a>
          </div>
        </div>
        <div className={css.heroCards}>
          <div className={css.heroCardsGrid}>
            {featuredListings.slice(0, 3).map(listing => {
              const { title, imageUrl } = listing;
              return (
                <div className={css.heroCard} key={imageUrl}>
                  <div>
                    <div
                      className={css.heroCardMedia}
                      style={{ backgroundImage: `url("${imageUrl}")` }}
                    ></div>
                    <div className={css.heroCardContent}>
                      <h3 className={css.heroCardTitle}>{title}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  onManageTopbarBelow: func.isRequired,
  // onManageRequestCatalog: func.isRequired,
  topbarBelow: bool,
  featuredListings: arrayOf(object).isRequired,
};

export default SectionHero;
